import Popup from 'reactjs-popup';

interface WarningPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  title?: string;
  message?: string;
  subMessage?: string;
  continueText?: string;
  cancelText?: string;
  titleColor?: string;
}

const WarningPopup = ({
  isOpen,
  onClose,
  onContinue,
  title = "Importante",
  message = "En sucursal de La Cisterna, nuestra área de Kinesiología se encuentra en 2do piso, por el momento no poseemos ascensor habilitado.",
  subMessage = "Le solicitamos considerar esta condición al momento de tomar su tratamiento.",
  continueText = "Entiendo, continuar",
  cancelText = "Buscar otra hora",
  titleColor = '#0066b3'
}: WarningPopupProps) => {
  return (
    <Popup open={isOpen} modal closeOnDocumentClick={false}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        textAlign: 'center'
      }}>
        <div style={{
          color: titleColor,
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px'
        }}>
          <span style={{ fontSize: '28px' }}>!</span>
          <span>{title}</span>
        </div>

        <p style={{
          fontSize: '16px',
          lineHeight: '1.5',
          marginBottom: '20px',
          color: '#333'
        }}>
          {message}
        </p>

        <p style={{
          fontSize: '16px',
          marginBottom: '30px',
          color: '#333'
        }}>
          {subMessage}
        </p>

        <div style={{
          display: 'flex',
          gap: '15px',
          justifyContent: 'center'
        }}>
          <button
            onClick={onClose}
            style={{
              backgroundColor: '#fff',
              color: '#52A92B',
              border: '2px solid #52A92B',
              borderRadius: '25px',
              padding: '8px 16px',
              fontSize: '14px',
              cursor: 'pointer',
              width: 'auto',
              minWidth: '120px',
              height: '36px',
              outline: 'none'
            }}
          >
            {cancelText}
          </button>

          <button
            onClick={onContinue}
            style={{
              backgroundColor: '#52A92B',
              color: 'white',
              border: 'none',
              borderRadius: '25px',
              padding: '8px 16px',
              fontSize: '14px',
              cursor: 'pointer',
              width: 'auto',
              minWidth: '120px',
              height: '36px',
              outline: 'none'
            }}
          >
            {continueText}
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default WarningPopup;